<template>
  <div>
    <click-to-edit
      v-if="scope.row.scale.type === 'manual'"
      v-model="scope.row.scale.bonus"
      :disabled="!isFieldAvailableForEdit(scope.row, 'scale.bonus')"
      placeholder=""
      digits-only
      @input="saveElementPropChange(scope.row, 'scale', {bonus:$event})"
    ></click-to-edit>
    <div v-else>{{scope.row.scale ? scope.row.scale.bonus : null}}</div>
  </div>
</template>

<script>

import ClickToEdit from "@/components/ClickToEdit";
import {ElementPropTableColumn} from "@/mixins/elementPropTableColumn";
import {variables} from "@/componentsCompany/smz/variables";

export default {
  name: 'element-bonus-table-column',
  components: {ClickToEdit},
  mixins: [ElementPropTableColumn],

  props: {},
  data() {
    return {}
  },
  computed: {},
  methods: {}
}

</script>

<style scoped lang="scss">
@import "@/assets/css/project-variables";

</style>
